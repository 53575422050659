import { forwardRef } from "react"
import PhoneInput, {
  DefaultInputComponentProps,
  Value,
} from "react-phone-number-input"

import { useTranslate } from "src/i18n/useTranslate"
import { MTextField, TMTextFieldProps } from "src/ui/MTextField/MTextField"

import "react-phone-number-input/style.css"

export function PhoneNumberInput(
  props: DefaultInputComponentProps & {
    onChange: (value: Value | undefined) => void
  }
) {
  const { t, langKeys } = useTranslate()

  return (
    <PhoneInput
      {...props}
      inputComponent={PhoneNumberTextField}
      countryCallingCodeEditable={props.countryCallingCodeEditable ?? false}
      label={props.label ?? t(langKeys.phone)}
      helperText={
        props.error && (props.helperText ?? t(langKeys.invalid_phone))
      }
      defaultCountry={props.defaultCountry ?? "US"}
      onChange={props.onChange}
      international={props.international ?? true}
      required={props.required ?? true}
      error={props.error ?? false}
    />
  )
}

const _PhoneNumberTextField = (
  props: TMTextFieldProps,
  ref: React.Ref<HTMLInputElement>
) => {
  return <MTextField ref={ref} {...props} />
}
export const PhoneNumberTextField = forwardRef(_PhoneNumberTextField)
